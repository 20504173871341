
section.wrapper{
    &.intro{
        padding: 0px 40px!important;
        &.main{
            margin-bottom: 0px;
        }
        
    }
    &>p{
        padding:0px 20px
    }
    
    .flex-row{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        .item {
            position: relative;
            width: 220px;
            font-size: 16px;
            margin-bottom: 35px;
            margin-right: 2.5%;
            cursor: pointer;
            img {
                width: 100%;
                -webkit-transition: all ease .4s;
                -o-transition: all ease .4s;
                transition: all ease .4s;
                -o-object-fit: cover;
                object-fit: cover;
                height: 205px;
                border-radius: 15px;
            }
        }
        .name {
            font-size: 18px;
            font-weight: 700;
            padding: 0 8px;
            margin-top: 8px;
        }
    }
    
}
