
$ogc-green: #295F11;
$ogc-green-hover:#1b440a;
#main.Portfolio{
    .section-green {
        background-color: #224225;
        background-image: linear-gradient(to bottom right, #2b4e29, #13841c);
    }
    #socialLinks{
        li{
            display: inline-block;
            margin: 0px 4px;
        }
    }
    .MuiButton-root{
        color:white;
        background-color:$ogc-green;
        &:hover{
            background-color:$ogc-green-hover
        }
        span.MuiButton-label{
            color:white;
            font-weight: 600;
        }
    }
    #social{
        text-align: center;
        padding: 16em 0 3em;
        background: #fff url(../../assests/images/backgrounds/ogc-background-newswatch.svg) no-repeat;
        background-size: 940px auto;
        background-position: center -120px;
        position: relative;
        background-color: #eef6f5;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, .1);
        background-position: center -120px;
        padding-top: 2em;
        border-top: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;
    }
    
}

@media (max-width: 399.98px) { 

}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    #insta-gallery > div:nth-child(26){
        display: none;
    }
}
  
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
     
}
  
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  
    
}
// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }
  
// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }
  
// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width