#main.NotFound{
    position:relative;
    .center.container{
    
        max-width: 300px;
        height: 100%;
        max-height: 200px;
        position: absolute;
        right: 0;
        left: 0;
        top: 98px;
        bottom: 0;
    }
    a{
        span{
            margin-right:10px;
        }
        &.button{
                text-align: center;
                display: block;
                -webkit-appearance: none;
                border: 1px solid transparent;
                padding: 2px 10px;
                border-radius: 3px;
                font-weight: 600;
                z-index: 1;
                max-width: 145px;
                text-decoration: none;
                margin: auto;
                margin-top: 38px;
            &.normal{
                background-color: #438841;
                color: #fff;
                &:hover{
                    background-color: rgb(223, 153, 24);
                }
            }
        }
    }
}