#socialLinks{
    li{
        display: inline-block;
        margin: 0px 0px;
        svg, img{
            height: 36px !important;
            width: 36px !important;
            opacity: .9;
            margin-right: 2px;
            &:hover{
                transform: scale(1.14);
                transition: .3s all;
            }
        }
    }
}
