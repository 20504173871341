.modal{
    transition:.4s all;
    display: none;
    .MuiSvgIcon-root{
        color: #ffffff;
        z-index: 102;
        transition: .3s all;
        position: absolute;
        right: -39px;
        top: -28px;
        transform: scale(1.3);
        opacity: .8;
        &:hover{
            transform:scale(1.4);
            opacity: 1;
            cursor:pointer;
        }
    }
    .contentContainer{
        display: block;
        max-width: 325px;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        z-index: 101;
        margin: auto;
        max-height: 622px;
        transition:.4s all;
        transform: translateY(200px);
        opacity: 0;
        &>iframe.instagram-media{
            z-index: 101;
            opacity: 0;
            transition:.4s all;
            max-height: 594px;
        }
    }
    #modalOverlay{
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        transition:.4s all;
    }
    &.open{
        display: block !important;
        z-index: 10000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        
        .contentContainer{
            transform: translateY(0px);
            opacity:1;
        }
        #modalOverlay{
            opacity: .4;
        }
    }
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .modal{
        .MuiSvgIcon-root{
            bottom: -644px;
            left: 0;
            right: 0 !important;
            margin: auto;
            transform: scale(1.9) !important;
            opacity: .9 !important;
        }
    }
    
}