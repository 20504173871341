
.swiper-button-next:after, .swiper-button-prev:after {
    
    color: white;
}
.microstory {
    background: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .1), 0 4px 6px 0 rgba(0, 0, 0, .05);
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    // min-height: 210px;
    min-height: 253px;
    a{
        .contactLink {
            color: #1969ca;
            text-decoration: none;
            -webkit-transition: color 0.1s linear;
            transition: color 0.1s linear;
        }
    }
    button{
        &.contactLink{
            color: #1969ca;
            text-decoration: none;
            transition: color 0.1s linear;
            background: none;
            border: none;
            padding:0px;
            &:hover{
                color: #ca8c19;
            }
        }
    }
    .microstory-image {
        border-right: 1px solid #E2E8F0;
        vertical-align: top;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        max-width: 50%;
        width: 300px;
        flex-shrink: 0;
    }
    .microstory-content {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
        color: #3F3F44;
    }
    .microstory-primary {
        padding: 20px 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .footer{
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 1.4;
        }
    }
    .microstory-quote p {
        font-size: 18px;
        line-height: 1.4;
    }
    
}
.caps {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .125em;
}
.gray-dark {
    color: #596981;
}
@media (max-width: 399.98px) { 

}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .microstory {
         height: 480px;
         max-width: 280px;
         .microstory-image {
             height: 190px;
         }
         .microstory-primary p {
             font-size: 15px;
         }
    }
    
 }
  
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .microstory{
        display: flex;
        flex-direction: column;
        max-width: 400px;
        
        margin: auto;
        .microstory-image{
            height: 300px;
            width: 100%;
            max-width: none;
        }
    }
}
  
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  
    
}
// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }
  
// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }
  
// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width