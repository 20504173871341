header{
    padding-top: 15px;
    background: #f0f0f0;
    box-shadow: 0 1px 1px 0 rgba(240, 240, 240, 0.1) inset;
    font-size: 13px;
    margin-bottom: 60px;
    h1{
        font-size: 30px;
        margin: 0.67em 0;
        font-weight: 600;
        padding-bottom: 30px;
    }
}