.intro{
    margin-bottom: 70px;
}
.project-listings{
    position: relative;
    background-color: #fff;
    background-image: linear-gradient(#fff, #ececec);
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
}
button{
    border: none;
    background: none;
    padding: 0px;
}

.learnLink{
    display: inline-flex;
    align-items: center;
    color: #1969ca;
    transition:.3s all;
    &:hover{
        color:rgb(223, 152, 22);
    }
    
    .MuiSvgIcon-root{
        margin-left: -2px;
        margin-top: 1px;
    }
}
.project-list{
    display: flex;
    margin-left:20px;
    margin-right: 20px;
    margin-bottom: 30px;
        .project-list-left{
            margin-bottom: 15px;
           
            img{
                margin-right: 20px;
                border-radius:5px;
                width:240px;
            }
        }
        .project-list-right{
            
        }
    
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .project-list{
        flex-direction: column;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 10px;
    }
}

// Small devices (landscape phones, less than 768px)
//@media (max-width: 767.98px) { }

// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width