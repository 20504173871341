$ogc-green: #295F11;
$ogc-green-hover:#1b440a;
nav {
    width:100vw;
    &.navbar-light{
        background-color: white;
    }
    &.scrolled{
        box-shadow: 0px 0px 17px 5px rgba(0, 0, 0, 0.27);
        transition: .3s;
    }
    #logo{
        img{
            width: 150px;
            margin-right: 7px;
            margin-top: 5px;
        }
    }
    .callLink{
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiSvgIcon-root{
            opacity: .8;
            color:$ogc-green;
            margin-right: 6px;
        }
    }
    a{
        text-decoration: none;
        position: relative;
        display: inline-block;
        color:black;
        &:hover{
            transition: .3s;
            color:$ogc-green;
        }
    }
    .MuiButton-root{
        background-color: $ogc-green;
        color:white;
        text-transform:none;
        &:hover{
            background-color: $ogc-green-hover;
        }
    }
    .faded{
        opacity:.5;
    }
    &.navbar-dark{
        background-color:$ogc-green;
        background-image: radial-gradient(circle at 50% top, rgba(0,103,3, .60) 0%, rgba(26,56,28, .37) 75%), radial-gradient(circle at right top, #0f6516 0%, rgba(77,162,74,.0) 57%);
        .MuiSvgIcon-root{
            color:white !important;
        }
        #logo{
            img{
                // width:175px;
            }
        }
        a{
            &.dropdown-item{
                color: #212529 !important;
            }
        }
        .MuiButton-root{
            background-color: $ogc-green;
            color:white;
            text-transform:none;
            &:hover{
                background-color: $ogc-green-hover;
            }
        }
    }
}