
form#blog-subscription{
    text-align: left;
    position: relative;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #d7d7d7;
    .blur{
        height: 40px;
        width: 76px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 4px;
        z-index: 1;
        background: rgb(2,0,36);
        background: linear-gradient(
    90deg
    , rgba(2,0,36,0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
        border-top: 1px solid #d7d7d7;
        border-right: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        &.submitted{
            background: linear-gradient(
    90deg
    , rgba(2,0,36,0) 0%, rgba(240,240,240,1) 50%, rgba(240,240,240,1) 100%);
        }
    }
    input[type="email"]{
        -webkit-appearance: none;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid #d7d7d7;
        box-shadow: 0 0 4px rgba(110,91,170,0);
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
    }
    input[type="submit"] {
        -webkit-appearance: none;
        position: absolute;
        bottom: 15px;
        right: 0;
        margin: 5px;
        width: 40px;
        height: 30px;
        border: 1px solid transparent;
        background-color: #5baa5b;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: .05em;
        padding: 0;
        font-weight: 700;
        text-align: center;
        color: #fff;
        z-index: 1;
    }
    .MuiSvgIcon-root{
        -webkit-appearance: none;
        position: absolute;
        bottom: 15px;
        right: 0;
        margin: 5px;
        width: 40px;
        height: 30px;
        border: 1px solid transparent;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: .05em;
        padding: 0;
        font-weight: 700;
        text-align: center;
        color: #5baa5b;
        z-index: 1;
    }
    .lds-ring{
        -webkit-appearance: none;
        position: absolute;
        right: 10px;
        top: -9px;
        margin: 5px;
        width: 40px;
        height: 30px;
        border: 1px solid transparent;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: .05em;
        padding: 0;
        font-weight: 700;
        text-align: center;
        color: #5baa5b;
        transform: scale(.35);
    }
}