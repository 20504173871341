form#contactform{
    
    position: relative;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    margin:auto;
    z-index: 10;
    box-shadow: 0 0 0 1px rgba(89,105,128,0.1), 0 3px 20px 0 rgba(89,105,128,0.3), 0 1px 2px 0 rgba(0,0,0,0.05);
    max-width: 500px;
    margin-top: 20px;
    #confirmationMsg{
        text-align: center;
    }
    .error{
        color:rgb(201, 0, 0);
        vertical-align: middle;
        display: flex;
        margin-bottom: 13px;
        .MuiSvgIcon-root{
            margin-top: -1px;
            margin-right: 8px;
        }
    }
    .input-group {
        
        margin-bottom: 15px;
        position: relative;
        label{
            display: inline-block;
            margin-bottom: .2rem;
            font-size: 15px;
            margin-left: 2px;
            font-weight: 600;
        }
        input, textarea, select {
            width: 100%;
            padding: 10px 5px;
            border: 1px solid #d3d3d3;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            &.error{
                border:solid red 1px !important;
            }
        }
        input[type="email"], input[type="text"], input[type="tel"], input[type="password"], input[type="url"], input[type="ipv4"], input[type="search"], input[type="number"], textarea, select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: 15px;
            padding: 5px;
            background: #fff;
            border: 1px solid #d3d3d3;
            box-shadow: 0 0 2px rgba(121,88,159,0);
            border-radius: 4px;
            color: #383E40;
            -webkit-transition: all 0.15s ease-out 0s;
            -moz-transition: all 0.15s ease-out 0s;
            transition: all 0.15s ease-out 0s;
        }
        input[type="email"]:focus, input[type="text"]:focus, input[type="tel"]:focus, input[type="password"]:focus, input[type="url"]:focus, input[type="ipv4"]:focus, input[type="search"]:focus, input[type="number"]:focus, textarea:focus, select:focus {
            background: white;
            box-shadow: 0 0 0 2px rgba(121,88,159,0.2);
            border-color: #36b100;
            outline: none;
        }
        
    }//input-group
    input[type="submit"], .submitBtn {
        -webkit-appearance: none;
        right: 0;
        margin: 5px;
        height: 30px;
        border: 1px solid transparent;
        // background-color: #5baa5b;
        background-color: #ff6d05;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: .05em;
        padding: 0 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        padding-right: 3px;
    }
    
    
}
.rc-anchor, .grecaptcha-badge{
    display: none;
    opacity: 0;
}
.inline-group{
    display: flex;
    width: 49%;
    align-items: center;
}