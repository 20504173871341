.instagram-image-thumb{
    max-width:150px;
    max-height:150px;
    padding: 3px;
    cursor: pointer;
    transition:.3s all;
    &.disabled{
        cursor:default;
    }
    &:hover{
        opacity:.9;
    }
    img{
        width: 100%;
        border-radius: 4px;
    }
}