//@import '../../swiper-vars.scss';
$themeColor: #007aff !default;
$colors: (
  'white': #ffffff,
  'black': #000000,
) !default;
// swiper-vars.scss end
:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
    width: 50vw;
    display: inline-block;
    // height: var(--swiper-navigation-size);
    // margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    // z-index: 10;
    cursor: pointer;
    align-items: center;
    //justify-content: center;

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    // font-family: swiper-icons;
    // font-size: var(--swiper-navigation-size);
    // text-transform: none !important;
    // letter-spacing: 0;
    // text-transform: none;
    // font-variant: initial;
    // line-height: 1;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    opacity: .8;
    transition:.3s all;
    &:hover{
        opacity: 1;
    }
  &:after {
    content: '‹ previous';
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    padding: 20px;
    
    &:hover{
        opacity: 1;
    }
  }
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    opacity: .8;
    transition:.3s all;
    
    &:hover{
        opacity: 1;
    }
  &:after {
    content: 'next ›';
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    padding: 20px;

    &:hover{
        opacity: 1;
    }
  }
  right: 10px;
  left: auto;
  text-align: right;
}

@each $navColorName, $navColorValue in $colors {
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-#{$navColorName} {
      --swiper-navigation-color: #{$navColorValue};
    }
  }
}
.swiper-button-lock {
  display: none;
}