* {
  box-sizing: border-box;
}

.card {
	margin: 15px;
	width: 200px;
	height: 200px;
	border-radius: 15px;
	padding: 1.5rem;
	background: white;
	position: relative;
	display: flex;
	align-items: flex-end;
	transition: 0.4s ease-out;
	box-shadow: 0px 7px 10px rgba(black, 0.5);
	background: none;
  .info{
    .MuiButton-root{
      background-color: #295F11;
      color: white;
      text-transform: none;
      box-shadow: 2px 2px 6px -1px rgba(0, 0, 0, .5);
      padding: 6px 22px;
      font-weight: 600;
      &:hover{
        background-color: #ff6d05;
      }
    }
  }
  &:hover {
    transform: translateY(10px);
    box-shadow: 0px 7px 5px rgba(black, 0.3);
    cursor: pointer;
    &:before {
      background: rgba(black, 0.6);
      opacity: 1;
    }
	.card .info {
		position: absolute;
		left: 20px;
		z-index: 3;
		color: white;
		opacity: 0;
		transform: translateY(30px);
		transition: 0.5s;
		height: calc(200px - 30px);
	}
    .info {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(19, 97, 0, 0.3);
    z-index: 2;
    transition: 0.5s;
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  .info {
    position: absolute;
    left: 20px;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
    height: calc(200px - 30px);

    h1 {
      margin: 0px;
    }

    p {
      letter-spacing: 1px;
      font-size: 15px;
      margin-top: 8px;
    }

    button {
      padding: 0.6rem;
      outline: none;
      border: none;
      border-radius: 3px;
      background: white;
      color: black;
      font-weight: bold;
      cursor: pointer;
      transition: 0.4s ease;

      &:hover {
        background: dodgerblue;
        color: white;
      }
    }
  }
}
@media (max-width: 399.98px) { 
  .card {
		width: 120px !important;
    height: 120px !important;
	}
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .card{
    .info{
      top: auto;
    }
  }
	
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
   .card {
	   width: 150px;
	   height: 150px;
	   margin: 15px;
     .info{
        top: auto !important;
      }
   }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  
  .card{
    .info{
      left: 0;
      right: 0;
      top: 25%;
      text-align: center;
      padding: 0px 8px;
      h1{
        font-size: 148%;
      }
      p{
        
      }
      .MuiButtonBase-root{
        padding: 6px 14px;
        z-index: 100;
        span.MuiButton-label{
          font-size: 12px;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width