@import-normalize;
$ogc-black:black;
html{
    width:100vw;
}
body {
    min-height: 100%;
    font-family: "BentonSans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    background: #fff;
    color:black;
    -webkit-font-smoothing: antialiased;
    width:100vw;
    padding-top:87px;
    #main{
        min-height:70vh;
        width:100vw;
    }
}

a{
    color:$ogc-black;
}
a:visited{
    color: #1969ca;
    // color:black;
    background-color: none;
    text-decoration: none;
}
section {
    
    margin-bottom: 0;
}
.text-center{

}
.wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}
input[type='submit']:hover{
    cursor:pointer;
}
div, span, input, a{
    outline:none;
}
body{
    overflow-x: hidden;
}