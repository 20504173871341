#overlay{
    background: white;
    height: calc(100% - 60px);
    width: calc(100% - 60px);
    position: absolute;
    z-index: 1000;
    opacity: .8;
    display: flex;
    justify-content: center;
    align-items: center;
    
}