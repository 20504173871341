$ogc-green: #295F11;
$ogc-green-hover:#1b440a;
.rm-hero-slider>div{
    background-color: rgb(233, 233, 233) !important;
}
.sliderContentWrapper{
    padding: 100px;
    color: white;
    width: 50%;
    float: right;
    height: 100%;
    display: block;
    & > div:not(#socialLinks){
        width:50%;
    }
    #socialLinks{
        width:100%;
    }
    h1{
        max-width:350px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4), 2px 2px 6px rgba(0, 0, 0, 0.45);
    }
    p{
        max-width:450px;
        font-size: 18px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
        font-weight: 500;
    }
    #socialLinks{
        ul{
            li{
                margin-right:10px;
            }
            padding-left: 0px;
            .MuiSvgIcon-root{
                color: white;
                opacity: 1;
                // -webkit-box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
                // -moz-box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
                // box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
                
            }
        }
    }
    .MuiButton-root, .MuiButtonBase-root{
        background-color: $ogc-green;
        color:white;
        text-transform:none;
        -webkit-box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
        -moz-box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
        box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.45);
        width: 180px;
        .MuiButton-label{
            font-weight:600;
        }
        &:hover{
            background-color: $ogc-green-hover;
        }
    }

}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .sliderContentWrapper{
        color: white !important;
        width: 70% !important;
        float: inherit;
        height: auto !important;
        margin: auto;
        padding:0px !important;
        margin-top: 60px;
        h1{
            font-size:38px;
        }
    }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  
    
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .sliderContentWrapper {
        padding: 100px;
        color: white;
        width: 100%;
        // float: right;
        height: 100%;
        display: block;
        
    }
 }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width