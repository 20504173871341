$ogc-green: #295F11;
$ogc-green-hover:#1b440a;
.section-green {
    background-color: #224225;
    background-image: linear-gradient(to bottom right, #2b4e29, #13841c);
}
section#hero{
    //height:300px;
    width:100%;
    max-width: 100%;
    position: relative;
    .carousel{
        height:100%;
        width:100%;
        position: relative;
    }
    .carousel__slide{
        position:relative;
    }
    .carousel__image{
        position:absolute;
        max-width: 100%;
    }
    
}
section:not(#hero){
    padding:40px 0px;
}
.middle{
    max-width: 600px;
    margin: auto;
}


.text-center{
    text-align: center;
}
#about{
    p{
        font-size: 16px;
    }
}

#services{
    position: relative;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ececec));
    background-image: linear-gradient(#fff, #ececec);
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
   

    &:before {
        z-index: 0;
        background-image: url(../../assests/images/backgrounds/ogc-background-newswatch.svg);
        background-size: 1400px auto;
        background-repeat: no-repeat;
        background-position: center bottom -2px;
        opacity: 0.65;
        
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .serviceRow{
        display:flex;
        
        justify-content: space-around;
        padding-top:20px;
        padding-bottom:20px;
    }
    .servicesLink{
        text-align: center;
        display: block;
        margin: auto;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #0f6b0f;
        text-transform: capitalize;
        background: none !important;
        max-width: 200px;
        &:hover{
            color: #113503
        }
    }
    
}
section#contact{
    
    background: url(../../assests/images/backgrounds/green-contact-bg.jpeg) center no-repeat $ogc-green;
    background-size: cover;
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    #services{
        .serviceRow {
            flex-wrap: wrap;
            margin: 0px;
            padding:0px;
        }
        .servicesLink{
            margin-top:20px;
        }
    }
	
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
  
}

// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width