.swiper-container {
    padding-bottom: 60px;
    &.swiper-container-horizontal{
        .swiper-slide {
            width: 80%;
            height: auto;
            max-width: 800px;
            -webkit-transition: opacity 0.3s;
            transition: opacity 0.3s;
            opacity: 0.25;
        }
        .swiper-slide-active {
            opacity: 1;
        }
        
    }
    
    .swiper-pagination-bullets {
        --swiper-pagination-color: #ffffff;
        position: absolute;
        padding: 20px 0;
        bottom: 10px;
        left: 0;
        width: 100%;
        outline: none;
    }
    .swiper-group {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        padding: 20px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        max-width: 1024px;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        .swiper-prev, .swiper-next {
            color: inherit;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            opacity: 0.8;
            -webkit-transition: opacity 0.3s;
            transition: opacity 0.3s;
        }
        .swiper-prev:before {
            content: "←";
            margin-right: 10px;
            font-weight: 700;
        }
        .swiper-next:after {
            content: "→";
            margin-left: 10px;
            font-weight: 700;
        }
        .swiper-prev:before{
            border-radius: 100%;
            background: rgba(255,255,255,0.1);
            width: 40px;
            height: 40px;
            display: inherit;
            -webkit-box-pack: inherit;
            justify-content: inherit;
            -webkit-box-align: inherit;
            align-items: inherit;
        }
        
    }
}
.swiper-next:after {
    border-radius: 100%;
    background: rgba(255,255,255,0.1);
    width: 40px;
    height: 40px;
    display: inherit;
    -webkit-box-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    align-items: inherit;
}
.swiper-button-prev, .swiper-button-next{
    position:block !important;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
   #Slider h2{
        margin-bottom: -7px !important;
        
   }
   .swiper-container.swiper-container-horizontal .swiper-slide {
    width: 82.6%;
}
   .microstory {
        height: 480px;
        max-width: 280px;
        .microstory-image {
            height: 190px;
        }
        .microstory-primary p {
            font-size: 15px;
        }
   }
   
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .swiper-slide{
        margin:0px !important;
    }

}

// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width