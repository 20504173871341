$horizontalPadding: 40px;

footer{
    padding-top: 15px;
    background: #f0f0f0;
    box-shadow: 0 1px 1px 0 rgba(240,240,240,0.1) inset;
    font-size: 13px;
    color: #666;
    line-height: 1.8em;
    -webkit-font-smoothing: antialiased;
    
    .wrapper{
        padding: 2em $horizontalPadding;
        max-width: calc(1200px + #{$horizontalPadding});
        margin: auto;
    }
    
    ul {
        list-style: none !important;
        padding-left: 0;
        font-weight: 300;
    }
    h1,h2,h3,h4,h5{
        line-height: 1.1em;
        padding: 0;
    }
    h3{
        color: #418a3b;
        font-size: 14px;
        font-weight: 400;
    }
    h4 {
        margin: 1.33em 0;
        font-size: 12px;
        text-align: left;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: #174619;
    }
    a{
        color: #666 im !important;
    }

    a:link, #heroku-footer a:visited {
        color: #666;
        text-decoration: none;
    }
    a:hover, a:active {
        color: #1a1a1a;
    }
    .cols{
        display: flex;
        flex-wrap: wrap;
        #footer__menu{
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows: auto;
            justify-content: space-evenly;
            width:70%;
            
        }
        #engagement{
            width:30%;
            #socialLinks{
                
                margin-top: 7px;
                
            }
        }
    }
    #tertiary{
        background: rgba(255,255,255,0.4);
        .wrapper{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        
        
        ul{

            display: inline-block;
            display: flex;
            li{
                
                padding:5px;
                a{
                    text-decoration: underline;
                }
            }
        }
        #footer__logo{
            width: 200px;
            max-width: 125px;
            opacity: .6;
        }
        #trademarkMobile{
            display: none;
        }
        #trademark{
            display: block;
        }
    }
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    footer{
        .cols{
            #footer__menu{
                width:100%;
                display: none;
                
            }
            #engagement{
                width:100%;
                #socialLinks{
                    text-align: center;
                    margin: auto;
                    margin-top: 20px;
                    margin-bottom: -10px;
                    transform: scale(1.5);
                    max-width: 200px;
                }
            }
        }
       
        #tertiary{
            .wrapper{
                flex-direction: column;
                text-align: center;
                justify-content: center;
                align-items: center;
            }
            #trademarkMobile{
                display: block;
                text-align: center;
                margin-bottom: 10px;
                margin-top: -13px;
            }
            #trademark{
                display: none;
            }
        }
        
    }
    
 }

// Small devices (landscape phones, less than 768px)
//@media (max-width: 767.98px) {  }

// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) {  }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width